<template>
  <div>
    <div class="titleBox">
      <div class="title">
        <img src="../../assets/unitBig.png" />
        <span>{{info.memberTypeName}}</span>
      </div>
      <span class="back" v-if="info.payStatus == 4">退费驳回</span>
      <span class="back" v-if="info.payStatus == 5">退费成功</span>
    </div>
    <div class="infoBox">
      <ul>
        <template v-for="item in list">
          <li :key="item.key">
            <span class="infoName">{{ item.name }}</span>
            <span class="infoText">{{ item.key=='season'?(item.value+'年'): item.value}}</span>

            <span class="success" v-if="item.key == 'paymentAmount'&&info.payStatus==2">缴费成功</span>
            <span class="success warning" v-if="item.key == 'paymentAmount'&&info.paymentMethod==2&&info.operationStatus==0">待审核</span>
            <span class="success error" v-if="item.key == 'paymentAmount'&&info.paymentMethod==2&&info.operationStatus==2">缴费驳回</span>
          </li>
        </template>
      </ul>

      <!-- 未退费未开发票  class="twoBtns"-->
      <div  v-if="(info.invoiceStatus==0||info.invoiceStatus==3) && info.payStatus == 2">
       <Button class="mar-t-25" color="#2b72ff" @click="makeOutInvoice">
          开具发票</Button
        >
        <Button class="mar-t-25" color="#2b72ff" @click="applyRefund">
          申请退费</Button
        >
      </div>
      <!-- 未退费已开发票/开具中 -->
      <div v-if="(info.invoiceStatus==1||info.invoiceStatus==2) && info.payStatus == 2">
        <Button class="mar-t-25" color="#2b72ff" block @click="applyRefund">
          申请退费</Button
        >
      </div>
      <!-- 退费审核中 -->
      <div v-if="info.payStatus == 3">
        <Button class="mar-t-25" color="#7f7f7f" block disabled> 审核中</Button>
      </div>
      <!-- 退费驳回未开发票   threeBtns-->
      <div class="twoBtns" v-if="(info.invoiceStatus==0||info.invoiceStatus==3) && info.payStatus == 4">
         <Button class="mar-t-25"  color="#2b72ff" @click="makeOutInvoice" v-if="info.invoiceStatus==0||info.invoiceStatus==4">
          开具发票</Button
        >
        <Button class="mar-t-25 " :class="{ml10:info.invoiceStatus==0||info.invoiceStatus==4,mr10:info.invoiceStatus==0||info.invoiceStatus==4}"  color="#2b72ff" @click="showReason = true">
          驳回原因</Button
        >
        <Button class="mar-t-25" color="#2b72ff" block @click="applyRefund">
          申请退费</Button
        >
      </div>
      <!-- 开具中/已开发票  退费驳回  threeBtns -->
      <div class="twoBtns" v-if="(info.invoiceStatus==1||info.invoiceStatus==2) && info.payStatus == 4">

        <Button class="mar-t-25" color="#2b72ff" @click="showReason = true">
          驳回原因</Button
        >
        <Button class="mar-t-25" color="#2b72ff" block @click="applyRefund">
          申请退费</Button
        >
      </div>

    </div>
    <Popup
      v-model="showReason"
      round
      closeable
      position="bottom"
      :style="{ height: '40%' }"
    >
      <p class="title">退费驳回原因</p>
      <p class="reason">
        {{ info.rejectReason}}
      </p>
    </Popup>
  </div>
</template>

<script>
import { Button, Popup } from "vant";
import {checkInvoice} from "@/api/my.js";
import {getMemberOrder} from "@/api/member.js"
export default {
  name: "memberRecord",
  components: {
    Button,
    Popup,
  },
  data() {
    return {
      info:{},
      showReason: false,
      list: [
        {
          name: "分支机构：",
          key: "branchDeptName",
          value: "",
        },
        { name: "联系人手机号：", key: "mobile", value: "" },
        { name: "单位名称：", key: "company", value: "" },
        { name: "联系人姓名：", key: "name", value: "" },
        {
          name: "缴费年度：",
          key: "season",
          value: "",
        },
        {
          name: "年费金额：",
          key: "paymentAmount",
          value: "",
        },
        {
          name: "缴费方式：",
          key: "paymentMethod",
          value: "",
        },
         {
          name: "开具发票：",
          key: "invoiceStatus",
          value: "",
        },
      ],
    };
  },
  created() {
      // let infos=this.$route.params.infos;
      // this.info={...infos};
      // this.list.map(item=>{
      //   if(item.key=="paymentAmount"){
      //     item.value="￥ "+infos['paymentAmount']
      //   }else if(item.key=="invoiceStatus"){
      //      item.value=["未开具","开票中","已开具"][infos.invoiceStatus]
      //   }else{
      //     item.value=infos[item.key]
      //   }
      // })
      if(this.$route.query.orderId){
        this.getOrder(this.$route.query.orderId);
      }
  },
  methods: {
    getOrder(orderId){
      getMemberOrder({orderId}).then(res=>{
        if(res.status=="S"){
           let infos=res.data;
            this.info={...infos};
            this.list.map(item=>{
              if(item.key=="paymentAmount"){
                item.value="￥ "+infos['paymentAmount']
              }else if(item.key=="invoiceStatus"){
                item.value=["未开具","开票中","已开具","已红冲"][infos.invoiceStatus]
              }else if(item.key=="paymentMethod"){
                 item.value=['线上缴费','线下缴费','线上汇款'][infos.paymentMethod]
              }
              else{
                item.value=infos[item.key]
              }
            })
        }
      })
    },
    // 申请退费
    applyRefund() {
      this.$router.replace(`/applyRefund?orderId=${this.info.id}&type=member`);
    },
    //开发票
    makeOutInvoice() {
      // console.log('....................')
      this.$router.replace(`/invoice?goodsId=1111&type=member&money=${this.info.paymentAmount}&orderId=${this.info.id}`);
      // checkInvoice({billId:this.info.id}).then(res=>{
      //   if(res.status=="S"){
      //
      //   }
      // })

    },
  },
};
</script>

<style lang="less" scoped>
  .ml10{
    margin-left: 10px;
  }
  .mr10{
    margin-right: 10px;
  }
::v-deep.van-popup {
  .title {
    font-size: 16px;
    font-weight: bold;
    padding: 16px;
    border-bottom: 1px solid #eee;
  }
  .reason {
    padding: 16px;
    line-height: 25px;
    font-size: 14px;
    color: #666;
    overflow-y:auto;
    max-height: calc(~"100% - 90px");
  }
}
.titleBox {
  background: url("../../assets/detailBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 1.4rem;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #fff;
  font-weight: bold;

  box-sizing: border-box;
  img {
    margin-right: 0.22rem;
    width: 0.28rem;
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: -0.15rem;
  }
  .back {
    color: #f62f2f;
    font-size: 14px;
    font-weight: normal;
    margin-top: -0.15rem;
  }
}
.infoBox {
  margin-top: -0.2rem;
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 0.1rem 0.12rem 0;
  ul {
    border-bottom: 1px solid #eee;
    padding: 0 0.13rem;
    padding-bottom: 0.3rem;
  }
  li {
    margin-top: 0.3rem;
    font-size: 14px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      // line-height: 20px;
      height: 20px;
      display: inline-block;
    }
    .infoName {
      width: 105px;
      flex:0 0 105px;
    }
    .infoText {
      color: #333;
    }
    .success {
      display: inline-block;

      margin-left: 0.15rem;
      padding: 0 0.05rem;
      background: #0fa84f;
      color: #fff;
      border-radius: 2px;
      font-size: 12px;
      height: 24px;
      line-height: 24px;
    }
    .warning{
        background: #2b72ff
    }
    .error{
       background: #ee0a24;
    }
  }
  .mar-t-25 {
    border-radius: 5px;
    height: 0.42rem;
    margin-top: 0.25rem;
    width: 100%;
  }
  .twoBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mar-t-25 {
      width: 47%;
    }
  }
  .threeBtns{
    .mar-t-25 {
      width: 31.5%;
    }
  }
}
</style>
